import React from 'react';
import { Router } from '@reach/router';

import TokenCheck from './TokenCheck';
import Dashboard from '../../Dashboard';
import ViewBook from '../../Book/scenes/ViewBook';
import ViewGroupBook from '../../Book/scenes/ViewGroupBook';

import AddBook from '../../Book/scenes/AddBook';

const AppRouter = () => {
    return (
        <Router>
            <TokenCheck path='/'>
                <Dashboard path='/' default/>
                <AddBook path='/book/new' />
                <ViewBook path='/book/:bookid' />
                <ViewGroupBook path='/groupbook/:bookid' />
            </TokenCheck>
        </Router>
    )
}

export default AppRouter;