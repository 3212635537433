import React, { useState } from 'react';
import _ from 'lodash'
import { navigate } from '@reach/router';
import styles from './BookSearchResult.module.scss';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button, FormErrors, Modal } from '@axeedge/go-pupil-components';
import { useMutation } from '@apollo/react-hooks';
import { STUDENT_ADD_READING_BOOK } from '../../../../services/graphql';
import { BOOK_TYPES } from '../../../../../../services/constants';
import BookItem from '../BookItem';
import ReadingBookItem from '../../../../../Dashboard/components/ReadingBookItem';
import { MAIN_DASHBOARD_QUERY } from '../../../../../Dashboard/services/graphql';
import { useTranslation } from 'react-i18next';

const PublisherSearchResults = ({loadingMore,  books, total, onFetchMore, setSearchOption}) => {
    
    const { t } = useTranslation();
    const [formErrors, setFormErrors] = useState([]);
    const [modalBookType, setModalBookType] = useState(null);

    const [studentAddReadingBook, { loading }] = useMutation(STUDENT_ADD_READING_BOOK, {
        update: (_, { data }) => {
            if (data.studentAddReadingBook.errors && data.studentAddReadingBook.errors.length !== 0) {
                setFormErrors(data.studentAddReadingBook.errors);
                return;
            } else if (data.studentAddReadingBook.studentReadingBook.id) {
                setModalBookType(null);
                navigate(`/book/${data.studentAddReadingBook.studentReadingBook.id}`);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: MAIN_DASHBOARD_QUERY }]
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: Yup.object().shape({
            bookType: Yup.number().typeError(t('select_book_type'))
        })
    });

    const onSubmit = ({ bookType })=> {
        studentAddReadingBook({
            variables: {
                bookId: modalBookType.book.id,
                bookType
            }
        })
    }


    return (
        <>
            {books.length > 0 ? (
                <>
                    <div className={styles.resultsList}>
                        {_.map(books, book => {
                            return <ReadingBookItem newBook={true} book={book} setModalBookType={setModalBookType} key={`book${book.id}`} />;
                        })}
                    </div>
                    <p>{t('showing_xx_of_total_books', {booksNumber: books.length, totalNumber: total})}</p>
                    {books.length < total && <Button outline fullWidth onClick={onFetchMore}>{loadingMore ? t('loading') : t('load_more')}</Button>}
                    {modalBookType && (
                        <Modal closeModal={() => setModalBookType(null) }>
                            <BookItem book={modalBookType.book} showSelectBtn={false} />
                            <hr />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={styles.bookResultSelectType}>
                                    <select ref={register} name="bookType" className="basic-form__text-select">
                                        {BOOK_TYPES.filter(book => book.type !== 4).map(book => (
                                            <option key={book.type} value={book.type}>{t(book.extended_label)}</option>
                                        ))}
                                    </select>
                                </div>
                                {errors.bookType && <p className="basic-form__hint">{errors.bookType.message}</p>}
                                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                                <Button type="submit" primary>{loading ? t('adding') : t('select_book')}</Button>
                            </form>
                        </Modal>
                )}
            </>
            ) : (
                <>
                    <h2>{t('hmm_we_couldnt_find_book')}</h2>
                    <p>{t('did_you_spell_title_correctly')}</p>
                    <p>{t('done_all_that_add_book_to_db')}</p>
                    <Button outline onClick={setSearchOption}>{t('add_new_book')}</Button>
                </>
            )}
        </>
    )
}

export default PublisherSearchResults;