import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormErrors } from '@axeedge/go-pupil-components';
import TextareaAutosize from 'react-textarea-autosize';
import RatingOptions from '../RatingOptions';
import { ADD_READING_BOOK_REVIEW, STUDENT_DONT_ADD_REVIEW } from '../../../../../../services/graphql';
import { useTranslation } from 'react-i18next';

const ReviewForm = ({ studentBookId, bookId }) => {

    const { t } = useTranslation();
    const [formErrors, setFormErrors] = useState([]);
    const [rating, setRating] = useState(0);
    const [tempRating, setTempRating] = useState();

    const [addReadingBookReview, { loading }] = useMutation(ADD_READING_BOOK_REVIEW, {
        update: (_, { data }) => {
            if (data.addReadingBookReview.errors && data.addReadingBookReview.errors.length !== 0) {
                setFormErrors(data.addReadingBookReview.errors);
                return;
            }
        }
    });

    const [dontAddReadingBookReview, { loading: dontAddLoading }] = useMutation(STUDENT_DONT_ADD_REVIEW, {
        update: (_, { data }) => {
            if (data.dontAddReadingBookReview.errors && data.dontAddReadingBookReview.errors.length !== 0) {
                setFormErrors(data.dontAddReadingBookReview.errors);
                return;
            }
            if (data.dontAddReadingBookReview.studentReadingBook.id) {
                navigate('/')
            }
        }
    });

    const schema = Yup.object().shape({
        text: Yup.string().required(t('please_enter_your_review')),
        rate: Yup.number().min(1, t('please_choose_rating'))
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const onSubmit = values => {
        const { rate, text } = values;
        addReadingBookReview({
            variables: {
                bookId,
                rate,
                text
            }
        })
    }

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className="heavy">{t('complete_quick_book_review')}</p>
            <p>{t('what_did_you_think_of_the_book')}</p>
            <div className="basic-form__group">
                <TextareaAutosize
                    className="basic-form__text-box"
                    placeholder={t('give_us_your_thoughts')}
                    name="text"
                    inputRef={register}
                    minRows={4}
                />
            </div>
            {errors.text && <p className="basic-form__hint">{errors.text.message}</p>}

            <p>{t('how_would_you_rate_book')}</p>
            <RatingOptions
                rating={rating}
                tempRating={tempRating}
                setRating={setRating}
                setTempRating={setTempRating}
            />
            <input
                type="hidden"
                name="rate"
                ref={register}
                defaultValue={rating}
            />
            {errors.rate && <p className="basic-form__hint">{errors.rate.message}</p>}
            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
            <ul>
                <li className='u-mb-1'><Button type="submit" primary>{loading ? t('saving') : t('add_your_review')}</Button></li>
                <li><Button type="button" outline onClick={() => dontAddReadingBookReview({variables: {studentReadingBookId: studentBookId}})}>{dontAddLoading ? t('saving') : t('no_review_just_finish_book')}</Button></li>
            </ul>
        </form>
    )
}

export default ReviewForm;
