import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
import { shared_en, shared_sv } from '@axeedge/go-shared-utils';

import translation_en from './locales/en/translation.json';
import translation_sv from './locales/sv/translation.json';

const languages = ['en', 'sv'];

const DETECTION_OPTIONS = {
    order: ['path', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'querystring', 'cookie', 'subdomain'],
};

const resources = {
    en: {
        translation: translation_en,
        shared: shared_en
    },
    sv: {
        translation: translation_sv,
        shared: shared_sv
    }
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: DETECTION_OPTIONS,
        fallbackLng: 'en',
        debug: false,
        supportedLngs: languages,
        ns: ['translation'],
        defaultNS: 'translation',
        resources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;