import React, { Fragment, useState, useContext } from 'react';
import styles from './Guidance.module.scss';
import cx from 'classnames';
import { AlertCircle, XCircle } from 'react-feather';
import {PupilAvatarViewer as Viewer} from '@axeedge/go-shared-components';
import { AuthContext } from '../../services/user/AuthProvider';
import { useTranslation } from 'react-i18next';
import boomer from '../../images/loader-boomer.png'
const Guidance = ({ text, title = 'Instructions' }) => {

    const [guidanceMobile, setGuidanceMobile] = useState(false);
    const { t } = useTranslation();

    const toggleGuidanceInfo = () => {
        guidanceMobile ? setGuidanceMobile(false) : setGuidanceMobile(true);
    }

    const { currentUser: user } = useContext(AuthContext);
    
    const renderGuidance = () => {
        return (
            <Fragment>
                <h3 className={styles.guidanceTitle}><AlertCircle size="30" /> {t(title)}</h3>
                <div className={styles.guidanceContent}>{text}</div>
            </Fragment>
        )
    }

    return(
        <div className={styles.guidance}>
            <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, '' )}` : boomer} className={styles.guidanceAvatar} />
            <div onClick={toggleGuidanceInfo} className={styles.guidanceInfoIcon}><AlertCircle /></div>
            <div className={styles.guidancePanel}>
                {renderGuidance()}
            </div>
            {
                guidanceMobile && (
                    <div className={styles.guidanceMobile}>
                        <div className={styles.guidanceMobileInner}>
                            <div onClick={toggleGuidanceInfo} className={styles.guidanceMobileClose}><XCircle size="30" /></div>
                            <div className={styles.guidanceMobileAvatarHolder}>
                                <div className={styles.guidanceMobileAvatar}>
                                <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, '' )}` : boomer} />
                                </div>
                            </div>
                            <div className={cx(styles.guidancePanel, styles.guidanceMobilePanel)}>
                                {renderGuidance()}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Guidance;
