import React from 'react';
import { useTranslation } from 'react-i18next';
import BookHeader from '../../../components/BookHeader';
import Guidance from '../../../../../components/Guidance';
import BookLogHistory from './BookLogHistory';
import Reading from './Reading';
import Completed from './Completed';
import { BOOK_STATUS } from '../../../../../services/constants';
import { useLocation } from 'react-use';

const BookHolder = ({ studentBook }) => {

    const location = useLocation();

    const { t } = useTranslation();
    const guidance = {
        started: t('here_you_can_log_reading_earn_gems'),
        completed: t('well_done_leave_review_rating'),
        reviewed: t('well_done_you_reviewed_the_book')
    }

    const renderContent = () => {
        switch(studentBook.status) {
            case BOOK_STATUS.new:
            case BOOK_STATUS.started:
                return {book: <Reading studentBook={studentBook} />, info: guidance.started}
            case BOOK_STATUS.completed:
                return {book: <Completed studentBook={studentBook} />, info: studentBook.book.myReview ? guidance.reviewed : guidance.completed}
            default:
                return {book: 'Nothing here', info: '-'}
        }
    }

    return(
        <div className="page-wrapper">
            <div className="page-wrapper__main">
                <div className="content-panel">
                    <BookHeader studentBook={studentBook} />
                    {location?.state?.book_readded && <p className='u-mb-3 heavy'>Book readded successfully!</p>}
                    {renderContent().book}
                    <BookLogHistory logs={studentBook.logs} status={studentBook.status} />
                </div>
            </div>
            <div className="page-wrapper__side">
                <Guidance text={renderContent().info} />
            </div>
        </div>
    )
}

export default BookHolder
