import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from '@reach/router';
import * as Yup from 'yup';
import { Button, FormErrors } from '@axeedge/go-pupil-components';
import { useMutation } from '@apollo/react-hooks';
import cx from 'classnames';
import styles from './Reading.module.scss';
import TextareaAutosize from 'react-textarea-autosize';
import { ADD_READING_BOOK_LOG } from '../../../../services/graphql';
import { useTranslation } from 'react-i18next';

const Reading = ({ studentBook }) => {

    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });

    const { t, i18n } = useTranslation();
    const [formErrors, setFormErrors] = useState([]);
    const [logSaved, setLogSaved] = useState(false);

    const pageStart = !!studentBook.logs.length ? studentBook.logs.slice(-1)[0].pageEnd + 1 : 1

    const [addReadingBookLog, { loading }] = useMutation(ADD_READING_BOOK_LOG, {
        onCompleted: ({ addReadingBookLog }) => {
            if (addReadingBookLog.errors && addReadingBookLog.errors.length !== 0) {
                setFormErrors(addReadingBookLog.errors);
                return;
            }
            else if(addReadingBookLog.studentReadingBook.id && !!addReadingBookLog.studentReadingBook.logs.length) {
                setLogSaved(true);
            }
        }
    });

    const schema = Yup.object().shape({
        showEntryField: Yup.boolean(),
        pageStart: Yup.number().typeError(t('please_enter_number'))
            .min(1, t('page_start_min_validation'))
            .max(100000, t('page_start_max_validation')),
        pageEnd: Yup.number().typeError(t('please_enter_number'))
            .max(100000, t('page_end_max_validation')),   
        entry: Yup.string().when('showEntryField', {
            is: true,
            then: Yup.string()
          })
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const onSubmit = values => {
        const { pageStart, pageEnd, entry, finished } = values;
        const currentDate = new Date();
        addReadingBookLog({
            variables: {
                studentReadingBookId: studentBook.id,
                readingDate: `${currentDate.toDateInputValue()} ${currentDate.toTimeString().slice(0,5)}:00`,
                pageStart,
                pageEnd,
                entry,
                finished
            }
        })
    }

    return(
        <Fragment>  
            {!logSaved ? (
                <form onSubmit={handleSubmit(onSubmit)} className={styles.logForm}>
                    <h3 className='heavy'>{t('log_your_reading')}</h3>
                    <p>{t('what_page_have_you_read_up_to')}</p>
                    <div className={styles.logFormPages}>
                        <input
                            type="hidden"
                            name="showEntryField"
                            ref={register}
                            defaultValue={!studentBook.hideComments}
                        />
                        <input
                            name="pageStart"
                            className={cx('basic-form__text-box', styles.logFormPagesField)}
                            ref={register}
                            type="number"
                            defaultValue={pageStart}
                        />to
                        <input
                            name="pageEnd"
                            className={cx('basic-form__text-box', styles.logFormPagesField)}
                            ref={register}
                            type="number"
                        />
                    </div>
                    {errors.pageStart && <p className="basic-form__hint">{errors.pageStart.message}</p>}
                    {errors.pageEnd && <p className="basic-form__hint">{errors.pageEnd.message}</p>}
                    {!studentBook.hideComments && (
                        <Fragment>
                            <p>{t('what_do_you_think_of_what_you_read')}</p>
                            <div className="basic-form__group">
                                <TextareaAutosize
                                    className="basic-form__text-box"
                                    placeholder={t('give_us_your_thoughts')}
                                    name="entry"
                                    minRows={4}
                                    inputRef={register}
                                />
                            </div>
                            {errors.entry && <p className="basic-form__hint">{errors.entry.message}</p>}
                        </Fragment>
                    )}

                    <p>{t('have_you_finished_the_book')}</p>
                    <div className="basic-form__group">
                        <div className={cx("basic-form__switch", `basic_form__switch_${i18n.language}`)}>
                            <input
                                id="finished"
                                className="switch-input"
                                type="checkbox"
                                name="finished"
                                ref={register}
                            />
                            <label htmlFor="finished" className="switch-label"></label>
                        </div>
                    </div>

                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                    <Button type="submit" primary>{loading ? t('saving') : t('add_reading_log')}</Button>
                </form>
            ) : (
                <div className='u-mb-3'>
                    <h1 className='heavy'>{t('awesome')}</h1>
                    <p>{t('reading_log_saved')}</p>
                    <Link to='/'><Button primary>{t('go_back_to_dashboard')}</Button></Link>
                </div>
            )}

        </Fragment>
    )
}

export default Reading;
