// Queries and mutations for the Home module
import { gql } from 'apollo-boost';

export const LOGGED_IN = gql`
    {
        loggedIn @client
        error @client
    }
`;

// Has the user been 401 logged out
export const FORCED_LOGOUT = gql`
    {
        forcedLogout @client
    }
`;

// Has the client thrown an error
export const ERROR = gql`
    {
        error @client
    }
`;

export const GET_USER = gql`
query currentStudent {
    currentStudent {
        avatarJson
        id
        boomerAvatarUrl
        updatedAt
        active
        firstName
        name
        loginCode
        expiredToken
        school {
            id
            allowSeens
            allowLikes
            schoolCode
        }
        classes {
            id
            name
        }
    }
}  
`;
