import React from 'react';
import cx from 'classnames';

import styles from './Tabs.module.scss';

const Tabs = ({ children, className }) => {
    return (
        <ul className={cx(className, styles.tabs)}>
            {children}
        </ul>
    )
}

export default Tabs;
