import React, { useState, useContext, useEffect } from "react";
import styles from '../Dashboard.module.scss';
import { Star } from "react-feather";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Modal } from "@axeedge/go-pupil-components";
import { GET_SCHOOL_REVIEWS } from "../services/graphql";
import boomer from '../../../images/loader-boomer.png'
import { STUDENT_ADD_READING_BOOK } from "../../Book/services/graphql";
import { AuthContext } from "../../../services/user/AuthProvider";
import { FormErrors } from "@axeedge/go-pupil-components";
import { navigate } from "@reach/router";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { BOOK_TYPES } from "../../../services/constants";

import { useTranslation } from 'react-i18next';

const stars = [1, 2, 3, 4, 5];

const limit = 5;

const Reviews = () => {
    const { t } = useTranslation();

    const [review, setReview] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const [showForm, setShowForm] = useState(false);

    const [reviews, setReviews] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const { currentUser: user } = useContext(AuthContext);


    const [getInitialReviews] = useLazyQuery(GET_SCHOOL_REVIEWS, {
        onCompleted: data => {
            const newReviews = data.schoolByCode.bookReviews || [];
            setReviews(newReviews);
            setHasMore(newReviews.length === limit);
        }
    })

    const [getMoreReviews, { loading: loadingMore }] = useLazyQuery(GET_SCHOOL_REVIEWS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            const moreReviews = data.schoolByCode.bookReviews || [];
            if (moreReviews.length > 0) {
                setReviews(prevReviews => prevReviews.concat(moreReviews));
            }
            setHasMore(moreReviews.length === limit);

        }
    });
    useEffect(() => {
        getInitialReviews({
            variables: {
                schoolCode: user?.school?.schoolCode,
                offset: 0,
                limit
            }
        });
    }, []);

    const loadMoreReviews = () => {
        getMoreReviews({
            variables: {
                schoolCode: user?.school?.schoolCode,
                offset: reviews.length,
                limit
            }
        })
    }


    const [studentAddReadingBook, { loading }] = useMutation(STUDENT_ADD_READING_BOOK, {
        onCompleted: ({ studentAddReadingBook }) => {
            if (studentAddReadingBook.errors && studentAddReadingBook.errors.length !== 0) {
                setFormErrors(studentAddReadingBook.errors);
                return;
            }
            else if (studentAddReadingBook?.studentReadingBook?.id) {
                setShowForm(false);
                setReview(null);
                navigate(`/book/${studentAddReadingBook.studentReadingBook.id}`);
            }
        },
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: Yup.object().shape({
            bookType: Yup.number().typeError(t('select_book_type'))
        })
    });

    const onSubmit = ({ bookType }) => {
        studentAddReadingBook({
            variables: {
                bookId: review.book.id,
                bookType
            }
        })
    }

    return (
        <div className={styles.reviewsPanel}>
            <h2 className={styles.reviewsPanelTitle}>Latest Book Reviews</h2>
            {reviews.length > 0 ?
                <>
                    {reviews.map(review => (
                        <div onClick={() => setReview(review)} className={styles.reviewItem} key={review.id}>
                            <div className={styles.reviewItemBoomer}>
                                <img src={review.author?.boomerAvatarUrl ? `${review.author.boomerAvatarUrl}?v=${review.author?.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.guidanceAvatar} />
                            </div>
                            <div className={styles.reviewItemBody}>
                                <h3 className={styles.reviewItemHead}>
                                    {review.author.name}
                                    <span className="u-m-left-2">
                                        {stars.map(star => {
                                            return <Star color="#000000" key={`star${star}`} size="18" fill={star <= review.reviewRate ? '#FFD506' : 'none'} />
                                        })}
                                    </span>
                                </h3>
                                <p className="u-small u-m-base-0">{review.book.title}</p>
                            </div>
                        </div>
                    ))}

                    {hasMore ? <button disabled={loadingMore} onClick={() => loadMoreReviews()} className="btn btn-outline">Load more</button> : limit < reviews.length && <p>End of list</p>}
                </> : <p>No reviews</p>
            }
            {review &&
                <Modal closeModal={() => setReview(null)}>
                    <div className={styles.reviewHeader}>
                        <h1 className="u-text-center u-m-base-2">{review.book.title}</h1>
                        <div className="u-text-center">
                            {stars.map(star => {
                                return <Star color="#000000" key={`star${star}`} size="26" fill={star <= review.reviewRate ? '#FFD506' : 'none'} />
                            })}
                        </div>
                    </div>
                    <p className="u-m-base-3 u-text-center">{review.author.name}</p>
                    <div className="newlines">
                        {review.reviewText}
                    </div>
                    {formErrors && <FormErrors errors={formErrors} />}
                    {!showForm ?
                    <div className="u-text-center u-m-top-2">
                        <button
                            type="button"
                            onClick={() => setShowForm(true)}
                            className="btn-primary"
                        >Add book to my reading</button>
                    </div>
                    : 

                    <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={styles.bookResultSelectType}>
                                    <select ref={register} name="bookType" className="basic-form__text-select">
                                        {BOOK_TYPES.filter(book => book.type !== 4).map(book => (
                                            <option key={book.type} value={book.type}>{t(book.extended_label)}</option>
                                        ))}
                                    </select>
                                </div>
                                {errors.bookType && <p className="basic-form__hint">{errors.bookType.message}</p>}
                                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                                <button type="submit" className="btn-primary">{loading ? t('adding') : t('select_book')}</button>
                            </form>
                    
                    </>}
                </Modal>
            }
        </div >
    )
}

export default Reviews;