import React from 'react';

import { useTranslation } from 'react-i18next';

const Completed = () => {
    const { t } = useTranslation();

    return(
        <div className='u-mb-3'>
            <h1>{t('awesome_you_completed_the_book')}</h1>
        </div>
    )
}

export default Completed;
