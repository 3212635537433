import React from 'react';
import { Link } from '@reach/router';
import styles from '../Dashboard.module.scss';
import * as Icon from 'react-feather';
import { getBookTypeLabel, BOOK_STATUS } from '../../../services/constants';
import _ from 'lodash';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const ReadingBookItem = ({ readingBook, book, newBook = false, setModalBookType = null }) => {
    const { t } = useTranslation();

    const bgColor = (title) => {
        let firstLetter;
        if (title) { firstLetter = title.toLowerCase().slice(-1) }
        let hex;
        switch (firstLetter) {
            case 'a':
            case 'b':
            case 'c':
            case 'd':
                hex = '#FF9D31'
                break;
            case 'e':
            case 'f':
            case 'g':
            case 'h':
                hex = '#178BCB'
                break;
            case 'i':
            case 'j':
            case 'k':
            case 'l':
            case 'm':
                hex = '#c33764'
                break;
            case 'n':
            case 'o':
            case 'p':
            case 'q':
                hex = '#9dc030'
                break;
            case 'r':
            case 's':
            case 't':
            case 'u':
            case 'v':
                hex = '#ffd653'
                break;
            case 'w':
            case 'x':
            case 'y':
            case 'z':
                hex = '#c76eff';
                break;
            default:
                hex = '#40e0d0'
        }
        return hex;
    }

    return (
        <>
            {newBook ?
                <div className={styles.bookListItem} onClick={() => setModalBookType({ show: true, book: book })}>
                    <div className='card card-hover' style={{ backgroundColor: bgColor(book.title) }}>
                        <div className={cx('card-body', styles.bookListItemBody)}>
                            <h2 className={styles.bookListItemTitle}>{_.truncate(book.title, { length: 45 })}</h2>
                            {readingBook &&
                                <span className={cx(styles.bookListItemLabel)}>{t(getBookTypeLabel(readingBook.bookType))}</span>
                            }
                            {(readingBook && readingBook.status === BOOK_STATUS.completed) ?
                                <Icon.Info size={36} color={'#000'} className={styles.bookListItemIcon} /> :
                                <Icon.PlusCircle size={36} color={'#000'} className={styles.bookListItemIcon} />
                            }
                        </div>
                    </div>
                </div>
                :
                <div className={styles.bookListItem} >
                    <Link className='card card-hover' to={readingBook.students ? `groupbook/${readingBook.id}` : `/book/${readingBook.id}`} style={{ backgroundColor: bgColor(book.title) }}>
                        <div className={cx('card-body', styles.bookListItemBody)}>
                            <h2 className={styles.bookListItemTitle}>{_.truncate(book.title, { length: 45 })}</h2>
                            {readingBook &&
                                <span className={cx(styles.bookListItemLabel)}>{t(getBookTypeLabel(readingBook.bookType || 4))}</span>
                            }
                            {(readingBook && readingBook.status === BOOK_STATUS.completed) ?
                                <Icon.Info size={36} color={'#000'} className={styles.bookListItemIcon} /> :
                                <Icon.PlusCircle size={36} color={'#000'} className={styles.bookListItemIcon} />
                            }
                        </div>
                    </Link>
                </div>
            }
        </>
    )
}

export default ReadingBookItem;
