import React from 'react';
import { Loader } from '@axeedge/go-pupil-components';
import { useQuery } from '@apollo/react-hooks';
import BookHolder from './components/BookHolder';

import { GET_STUDENT_BOOK } from '../../services/graphql';

const ViewBook = ({ bookid }) => {
    const {data, error, loading} = useQuery(GET_STUDENT_BOOK, {
        variables: { id: bookid}
    });


    if (error) {
        return <p>{error.message}</p>
    }

    if (loading && !data) {
        return (
            <div className="page-wrapper">
                <div className="page-wrapper__main">
                    <div className="content-panel">
                        <Loader />
                    </div>
                </div>
            </div>
        )
    }

    if (data.studentBook && data.studentBook.id) {
        return <BookHolder studentBook={data.studentBook} />
    }

    return null;

}

export default ViewBook
