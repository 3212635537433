import { useEffect, useState, useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useLocation } from 'react-use';

import { getUrl, APPS } from '@axeedge/go-shared-utils';

import { FORCED_LOGOUT } from '../services/graphql';
import { AuthContext } from '../../../services/user/AuthProvider';

import { APP_NAME } from '../../../services/constants';

const RouteCapture = () => {

    const [targetRoute, setTargetRoute] = useState('');
    const client = useApolloClient();
    const { data } = useQuery(FORCED_LOGOUT);
    const { setCurrentUser } = useContext(AuthContext);


    const locationState = useLocation();

    useEffect(() => {
        setTargetRoute(locationState.pathname);
    }, [setTargetRoute, locationState]);

    const forceLogout = () => {
        ls.clear();
        client.clearStore();
        setCurrentUser(null);
        window.location.href = `${getUrl(APPS.go_portal_pupil)}/redirectAfterLogin/${APP_NAME}?targetUrl=${targetRoute}`;
    }

    useEffect(() => {
        if (ls('go_student_user') && ls('go_student_user') === 'undefined') {
            forceLogout();
        }
    });

    if (data && data.forcedLogout) {
        forceLogout()
    }

    return null;
}

export default RouteCapture;
