import React from 'react';
import { useTranslation } from 'react-i18next';
import Guidance from '../../../../../components/Guidance';
import BookLogHistory from './BookLogHistory';
import Reading from './Reading';
import Completed from './Completed';
import { BOOK_STATUS } from '../../../../../services/constants';
import headerStyles from '../../../components/BookHeader/BookHeader.module.scss';

const BookHolder = ({ groupBook }) => {

    const { t } = useTranslation();
    const guidance = {
        started: t('here_you_can_log_reading_group_book'),
        completed: t('well_done_you_finished_the_book'),
    }
    const renderContent = () => {
        switch (groupBook.status) {
            case BOOK_STATUS.new:
            case BOOK_STATUS.started:
                return { book: <Reading groupBook={groupBook} />, info: guidance.started }
            case BOOK_STATUS.completed:
                return { book: <Completed />, info: guidance.completed }
            default:
                return { book: '-', info: '-' }
        }
    }

    return (
        <div className="page-wrapper">
            <div className="page-wrapper__main">
                <div className="content-panel">
                    <div className={headerStyles.bookHeader}>
                        <div>
                            <p><span className='label label-primary'>{t('Group Book')}</span></p>
                            <h2>{groupBook.book.title}</h2>
                            <p className='u-mb-0'>{groupBook.book.author}</p>
                        </div>
                    </div>
                    {renderContent().book}
                    <BookLogHistory logs={groupBook.logs} />
                </div>
            </div>
            <div className="page-wrapper__side">
                <Guidance text={renderContent().info} />
            </div>
        </div>
    )
}

export default BookHolder
