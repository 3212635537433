import React, { useState, Fragment } from 'react';
import { navigate } from '@reach/router';
import styles from './BookSearchResult.module.scss';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button, FormErrors, Modal } from '@axeedge/go-pupil-components';
import { useMutation } from '@apollo/react-hooks';
import { STUDENT_ADD_READING_BOOK, ADD_NEW_READING_BOOK } from '../../../../services/graphql';
import { BOOK_TYPES } from '../../../../../../services/constants';
import BookItem from '../BookItem';
import ReadingBookItem from '../../../../../Dashboard/components/ReadingBookItem';
import { MAIN_DASHBOARD_QUERY } from '../../../../../Dashboard/services/graphql';
import { useTranslation } from 'react-i18next';

const BookSearchResult = ({ book, studentId }) => {

    const { t } = useTranslation();
    const [formErrors, setFormErrors] = useState([]);
    const [modalBookType, setModalBookType] = useState(null);

    const [studentAddReadingBook, { loading }] = useMutation(STUDENT_ADD_READING_BOOK, {
        update: (_, { data }) => {
            if (data.studentAddReadingBook.errors && data.studentAddReadingBook.errors.length !== 0) {
                setFormErrors(data.studentAddReadingBook.errors);
                return;
            } else if (data.studentAddReadingBook.studentReadingBook.id) {
                setModalBookType(null);
                navigate(`/book/${data.studentAddReadingBook.studentReadingBook.id}`);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: MAIN_DASHBOARD_QUERY }]
    });

    const [studentAddNewReadingBook, { loading: newLoading }] = useMutation(ADD_NEW_READING_BOOK, {
        update: (_, { data }) => {
            if (data.addReadingBookAndAssignToStudent.errors && data.addReadingBookAndAssignToStudent.errors.length !== 0) {
                setFormErrors(data.addReadingBookAndAssignToStudent.errors);
                return;
            } else if (data.addReadingBookAndAssignToStudent.studentReadingBook.id) {
                setModalBookType(null);
                navigate(`/book/${data.addReadingBookAndAssignToStudent.studentReadingBook.id}`);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: MAIN_DASHBOARD_QUERY }]
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: Yup.object().shape({
            bookType: Yup.number().typeError(t('select_book_type'))
        })
    });

    const onSubmit = ({ bookType })=> {
        book.existsInDb ? 
            studentAddReadingBook({
                variables: {
                    bookId: book.id,
                    bookType
                }
            })
        :
            studentAddNewReadingBook({
                variables: {
                    studentId: studentId,
                    author: book.author,
                    title: book.title,
                    isbn: book.isbn,
                    isbn13: book.isbn13,
                    coverUrl: book.coverUrl,
                    description: "test description",
                    bookType,
                    hideComments: false,
                }
            })
    }

    return(
        <Fragment>
            <ReadingBookItem newBook={true} book={book} setModalBookType={setModalBookType} key={`book${book.id}`} />

            {modalBookType && (
                <Modal closeModal={() => setModalBookType(null) }>
                    <BookItem book={book} showSelectBtn={false} />
                    <hr />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.bookResultSelectType}>
                            <select ref={register} name="bookType" className="basic-form__text-select">
                                {BOOK_TYPES.filter(book => book.type !== 4).map(book => (
                                    <option key={book.type} value={book.type}>{t(book.extended_label)}</option>
                                ))}
                            </select>
                        </div>
                        {errors.bookType && <p className="basic-form__hint">{errors.bookType.message}</p>}
                        {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                        <Button type="submit" primary>{(loading || newLoading) ? t('adding') : t('select_book')}</Button>
                    </form>
                </Modal>
            )}
        </Fragment>
    )
}

export default BookSearchResult;
