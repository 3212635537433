// Queries and mutations unique to the Book module
import { gql } from 'apollo-boost';

const bookQuery = `
id
author
existsInDb
isbn
isbn13
title
coverUrl
description
rate
myReview {
    id
    date
    reviewText
    reviewRate
    views {
        id
        author {
            id
            displayName
        }
    }
    likes {
        id
        author {
            id
            displayName
        }
    }
}
`;

const groupBookQuery = `
id
author
existsInDb
isbn
isbn13
title
coverUrl
description
rate
`;

const logsQuery = `
id
author {
    id
    name
    role
}
isCompletion
entry
pageStart
pageEnd
date
readingDate
problemWords {
    id
    word
}
views {
    id
    author {
        id
        displayName
    }
}
likes {
    id
    author {
        id
        displayName
    }
} 
`;

export const STUDENT_ADD_READING_BOOK = gql`
mutation studentAddReadingBook($bookId: ID!, $bookType: Int!) {
    studentAddReadingBook(bookId: $bookId, bookType: $bookType) {
        studentReadingBook {
            id
            hideComments
            bookType
            addedBy {
                id
                displayName
            }
            book {
                ${bookQuery}
            }
            status
        }
        errors
    }
}
`;

export const ADD_NEW_READING_BOOK = gql`
mutation addReadingBookAndAssignToStudent($title: String!,
    $author: String,
    $isbn: String,
    $isbn13: String,
    $coverUrl: String,
    $description: String,
    $bookType: Int!,
    $hideComments: Boolean!,
    $publisherId: String,
    $studentId: ID!) {
        addReadingBookAndAssignToStudent(title: $title, author: $author, isbn: $isbn, isbn13: $isbn13, coverUrl: $coverUrl, description: $description, studentId: $studentId, bookType: $bookType, hideComments: $hideComments, publisherId: $publisherId) {
            studentReadingBook {
                id
                hideComments
                bookType
                addedBy {
                    id
                    displayName
                }
                book {
                    ${bookQuery}
                }
                status
            }
        errors
    }
}
`;

export const DELETE_READING_BOOK = gql`
mutation deleteReadingBookFromStudent($studentId: ID!, $readingBookId: ID!) {
    deleteReadingBookFromStudent(studentId: $studentId, readingBookId: $readingBookId) {
        errors
        deleted
    }
}
`;

export const ADD_READING_BOOK_LOG = gql`
mutation addReadingBookLog($studentReadingBookId: ID!, $pageStart: Int!, $pageEnd: Int!, $entry: String, $finished: Boolean!, $readingDate: String!) {
    addReadingBookLog(studentReadingBookId: $studentReadingBookId, pageStart: $pageStart, pageEnd: $pageEnd, entry: $entry, finished: $finished, readingDate: $readingDate) {
        studentReadingBook {
            id
            bookType
            book {
                ${bookQuery}
            }
            logs {
                ${logsQuery}
                views {
                    id
                    author {
                        id
                        displayName
                    }
                }
                likes {
                    id
                    author {
                        id
                        displayName
                    }
                }
            }
            status
        }
        errors
    }
}
`;

export const ADD_GROUP_READING_BOOK_LOG = gql`
mutation addGroupReadingBookLog($groupReadingBookId: ID!, $pageStart: Int!, $pageEnd: Int!, $entry: String, $finished: Boolean!, $readingDate: String!, $studentIds: [ID!]) {
    addGroupReadingBookLog(groupReadingBookId: $groupReadingBookId, pageStart: $pageStart, pageEnd: $pageEnd, entry: $entry, finished: $finished, readingDate: $readingDate, studentIds: $studentIds) {
        groupReadingBook {
            id
            book {
                ${groupBookQuery}
            }
            logs {
                ${logsQuery}
            }
            status
            students {
                id
            }
        }
        errors
    }
}
`;

export const ADD_READING_BOOK_REVIEW = gql`
mutation addReadingBookReview($bookId: ID!, $text: String!, $rate: Int!) {
    addReadingBookReview(bookId: $bookId, text: $text, rate: $rate) {
        readingBook {
            ${bookQuery}
        }
        errors
    }
}
`;

export const STUDENT_DONT_ADD_REVIEW = gql`
mutation dontAddReadingBookReview($studentReadingBookId: ID!) {
    dontAddReadingBookReview(studentReadingBookId: $studentReadingBookId) {
        studentReadingBook {
            id
            hideComments
            bookType
            noReview
            book {
                ${bookQuery}
            }
            status
        }
        errors
    }
}
`;


export const GET_STUDENT_BOOK = gql`
query studentBook($id: String!) {
    studentBook(id: $id) {
        id
        status
        bookType
        hideComments
        noReview
        addedBy {
            id
            displayName
        }
        book {
            ${bookQuery}
        }
        logs {
            ${logsQuery}           
        }
    }
}
`;

export const GET_GROUP_BOOK = gql`
query groupBook($id: String!) {
    groupBook(id: $id) {
        id
        status
        hideComments
        addedBy {
            id
            displayName
        }
        logs {
            ${logsQuery}
        }
        book {
            ${groupBookQuery}
        }
        students {
            id
        }
    }
}
`;



export const SEARCH_BOOK_ISBN = gql`
query bookByIsbn($isbn: String!) {
    bookByIsbn(isbn: $isbn) {
        ${bookQuery}
    }
}
`;

export const SEARCH_BOOK_TITLE = gql`
query booksByTitle($title: String!, $startIndex: Int, $searchApi: Boolean) {
    booksByTitle(title: $title, searchApi: $searchApi, startIndex: $startIndex) {
       total
       items {
            ${bookQuery}
       }
    }
}
`;

//used for Read again
export const ASSIGN_BOOK_TO_STUDENT = gql`
mutation assignReadingBookToStudent($bookId: ID!, $studentId: ID!, $bookType: Int!, $hideComments: Boolean!, $isReread: Boolean) {
    assignReadingBookToStudent(bookId: $bookId, studentId: $studentId, bookType: $bookType, hideComments: $hideComments, isReread: $isReread) {
        errors
         studentReadingBook {
            id
            hideComments
            bookType
            addedBy {
                id
                displayName
            }
            book {
                ${bookQuery}
            }
            status
        }
    }
}
`;
