// This is the root of our app
// Will contain the app client and auth provider and link to router

import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ls from 'local-storage';

import { getClient } from '@axeedge/go-shared-utils';
import { ErrorPage, Ssl } from '@axeedge/go-shared-components';

import { AuthProvider } from '../../services/user/AuthProvider';
import { APP_NAME } from '../../services/constants';
import AppRouter from './components/AppRouter';

const hostname = window && window.location && window.location.hostname;

Bugsnag.start({
    apiKey: '112ef5bd7b0c7c164caf36e3c5198c23',
    plugins: [new BugsnagPluginReact()],
    metadata: {
        app: 'Pupil Go Read',
        releaseName: hostname === 'localhost' ? 'development' : !hostname.includes('staging') ? 'production' : 'staging'
    },
    enabledReleaseStages: [ 'production', 'staging' ],
    onError: (event) => {
        const user = JSON.parse(ls('go_student_user'));
        if (user && user.id && user.name) {
            event.setUser(user.id, '', `${user.name}`);
        } else {
            event.setUser('unknown', 'unknown', 'unknown');
        }
    }
});

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

const Home = () => {
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <ApolloProvider client={getClient(APP_NAME)} >
                <Ssl />
                <AuthProvider>
                    <AppRouter />
                </AuthProvider>
            </ApolloProvider>  
        </ErrorBoundary>
    )
}

export default Home;
