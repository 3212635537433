import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button } from '@axeedge/go-pupil-components';
import { useLazyQuery } from '@apollo/react-hooks';
import beep from "../../../../../../sounds/beep.mp3";
import { SEARCH_BOOK_ISBN } from '../../../../services/graphql';
import BookSearchResult from '../BookSearchResult';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import { AuthContext } from '../../../../../../services/user/AuthProvider';
import cx from 'classnames';
import styles from './isbnSearch.module.scss';
import { useTranslation } from 'react-i18next';

const IsbnSearch = ({ close, setSearchOption }) => {
    const { t } = useTranslation();

    let audio = new Audio(beep)
    const { currentUser: user } = useContext(AuthContext)
    const [scannedCode, setScannedCode] = useState("");
    const [bookByIsbn, { loading, data, error}] = useLazyQuery(SEARCH_BOOK_ISBN, { fetchPolicy: "network-only" }
    )

    useEffect(() => {
        if (scannedCode !== "") {
            //audio.play();
            const playedPromise = audio.play();
            if (playedPromise) {
                playedPromise.then(_ => {
                    // Autoplay started!
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }, [scannedCode])
    const [code, setCode] = useState('');

    const [showScanner, setShowScanner] = useState(true);

    const { register, handleSubmit, errors } = useForm({
        validationSchema: Yup.object().shape({
            search_term: Yup.string().required(t('please_enter_isbn'))
        })
    });

    const onSubmit = ({ search_term }) => {
        setShowScanner(false)
        bookByIsbn({variables: {isbn: search_term.replace(/-|\s/g,"") }})
    }


    return(
        <>
            <h1>{t('find_or_scan_your_book')}</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="basic-form__group">
                    <input
                        name="search_term"
                        placeholder={t('search_by_isbn')}
                        className="basic-form__text-box u-mr-2"
                        ref={register}
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.currentTarget.value)}
                    />
                    <Button type="submit" primary className="u-mr-2">{loading ? t('searching') : t('search')}</Button>
                    <Button outline={true} onClick={close}>{t('button.cancel')}</Button>
                </div>
                {errors.search_term && <p className="basic-form__hint">{errors.search_term.message}</p>}
            </form>
            {showScanner ?
                <div className={styles.isbnHolder}>
                    <div className={styles.vid}>
                        <BarcodeScannerComponent
                                width={250}
                                height={"auto"}
                                onUpdate={(err, result) => {
                                if (result) {
                                    setScannedCode(result.text);
                                    setCode(result.text)
                                }
                                }
                                }
                            />
                    </div>
                    <ul className={cx(styles.help, "u-m-top-20")}>
                        <li>1. {t('use_camera_to_scan_book')}<span className={styles.helpSmall}>{t('hold_book_flat_to_camera')}.</span></li>
                        <li className="u-m-top-10">2. {t('once_number_scanned_hit_search')}</li>
                    </ul>
                </div>
                :
                <>
                    {data && data.bookByIsbn && 
                        <>
                            <BookSearchResult studentId={user.id} book={data.bookByIsbn} />
                            <Button outline onClick={() => {setShowScanner(true); setCode('')}}>{t('try_again')}</Button>
                        </>
                    }
                    {data && !data.bookByIsbn  && 
                        <>
                            <h2>{t('hmm_we_couldnt_find_book')}</h2>
                            <Button outline onClick={setSearchOption} className="u-mr-2">{t('add_new_book')}</Button>
                            <Button outline onClick={() => {setShowScanner(true); setCode('')}}>{t('try_again')}</Button>
                        </>    
                    }
                    {error && 
                        <>
                            <p>{error.message}</p>
                            <Button outline onClick={() => {setShowScanner(true); setCode('')}}>{t('try_again')}</Button>
                        </>        
                    }
                </>
            }
        </>
    )
}

export default IsbnSearch;
