import React from 'react';
import cx from 'classnames';

import styles from './Tab.module.scss';

const Tab = ({ children, disabled, onClick, active }) => {

    return (
        <li
            className={cx(styles.tab, {[styles.disabled]: disabled}, {[styles.active]: active})}
            onClick={onClick}
        >
            {children}
        </li>
    )
}

export default Tab;
