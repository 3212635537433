import { APPS } from '@axeedge/go-shared-utils';

export const APP_NAME = APPS.go_read_pupil;

export const BOOK_STATUS = {
    new: 0,
    started: 1,
    completed: 2
}

export const BOOK_TYPES = [
    {type: 1, label: 'Reading Book', extended_label: 'School Reading Book'},
    {type: 2, label: 'Library Book', extended_label: 'Library Reading Book'},
    {type: 3, label: 'Home Book', extended_label: 'Home Reading Book'},
    {type: 4, label: 'Group Book', extended_label: 'Group Reading Book'},

]

export const getBookTypeLabel = (type) => {
    const el = BOOK_TYPES.find(el => el.type === type)
    return el && el.label
}
