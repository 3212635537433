import React from 'react';

const LogoutButton = ({ children, classes, onLogout }) => {
    return (
        <button
            className={classes}
            onClick={() => {
                onLogout();
            }}
        >
            {children}
        </button>
    );
}

export default LogoutButton;