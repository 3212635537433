import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { MAIN_DASHBOARD_QUERY } from './services/graphql';
import { Loader } from '@axeedge/go-pupil-components';
import ReadingBooks from './components/ReadingBooks';
import { AuthContext } from '../../services/user/AuthProvider';

const Dashboard = () => {

    const { setCurrentUser } = useContext(AuthContext);

    const { data, error, loading } = useQuery(MAIN_DASHBOARD_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            data && data.currentStudent && setCurrentUser(data.currentStudent)
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading && !data) {
        return (
            <div className="page-wrapper">
                <div className="page-wrapper__main">
                    <div className="content-panel">
                        <Loader />
                    </div>
                </div>
            </div>
        )
    }

    if (data && data.currentStudent && data.currentStudent.readingBooks) {
        const allBooks = [...data.currentStudent.readingBooks, ...data.currentStudent.groupReadingBooks];
        return <ReadingBooks firstName={data.currentStudent.firstName} readingBooks={allBooks} readingBand={data.currentStudent.currentReadingRank && data.currentStudent.currentReadingRank.readingBandRank} />
    }

    return null;

}

export default Dashboard;
