import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import RatingOptions from '../RatingOptions';
import { Button } from '@axeedge/go-pupil-components';
import { Heart, Eye } from 'react-feather';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tippy';
import styles from '../../../BookLogHistoryItem/BookLogHistoryItem.module.scss';
import cx from 'classnames';

const MyReview = ({ review, allowSeens, allowLikes }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <h2>{t('congrats_heres_your_review')}</h2>
            <div>
                {t('date')}: {moment(review.date).format('DD/MM/YYYY')}

                {allowLikes &&
                    <span className='u-ml-2 u-mr-1'>
                        <Tooltip
                            disabled={!review.likes || review.likes.length === 0}
                            size='big'
                            animateFill={false}
                            html={
                                <div className='u-text-left'>
                                    {review.likes && review.likes.length > 0 && (
                                        <>
                                            <p className='u-mb-0'>Liked by:</p>
                                            {review.likes.map(el => <p key={`like-${el.id}`} className='u-mb-0'>{el.author.displayName}</p>)}
                                        </>
                                    )}
                                </div>
                            }
                        >
                            <span
                                className={(cx(styles.likeIcon, { [styles.likeIconActive]: review.likes.length > 0 }))}
                            ><Heart /></span>{review.likes && review.likes.length > 0 && <>({review.likes.length})</>}
                        </Tooltip>
                    </span>
                }
                {allowSeens &&
                    <span className='u-mr-1'>
                        <Tooltip
                            disabled={!review.views || review.views.length === 0}
                            size='big'
                            animateFill={false}
                            html={
                                <div className='u-text-left'>
                                    {review.views && review.views.length > 0 && (
                                        <>
                                            <p className='u-mb-0'>Seen by:</p>
                                            {review.views.map(el => <p key={`seen-${el.id}`} className='u-mb-0'>{el.author.displayName}</p>)}
                                        </>
                                    )}
                                </div>
                            }
                        >
                            <span
                                className={(cx(styles.seenIcon, { [styles.seenIconActive]: review.views.length > 0 }))}
                            ><Eye /></span>{review.views && review.views.length > 0 && <>({review.views.length})</>}
                        </Tooltip>
                    </span>
                }

            </div>
            <p>{review.reviewText}</p>
            <RatingOptions
                rating={review.reviewRate}
                displayOnly={true}
            />
            <Link to='/'><Button primary>{t('go_back_to_dashboard')}</Button></Link>
        </Fragment>
    )
}

export default MyReview;
