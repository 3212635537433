import React, { Fragment, useState, useContext } from 'react';
import { Button } from '@axeedge/go-pupil-components';
import BookLogHistoryItem  from '../BookLogHistoryItem';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../../services/user/AuthProvider';

const BookLogHistory = ({ logs }) => {
    const { t } = useTranslation();

    const visibleLogs = 5;
    const [maxVisible, setMaxVisible] = useState(visibleLogs);
    const [allShown, setAllShown] = useState();
    const { currentUser } = useContext(AuthContext);

    const toggleLogs = () => {
        if (maxVisible === logs.length) {
            setMaxVisible(visibleLogs)
            setAllShown(false);
        } else {
            setMaxVisible(logs.length)
            setAllShown(true)
        }
    }

    return(
        <Fragment>
            <h3 className='heavy u-mb-2'>{t('reading_log_history')}</h3>
            {
                !!logs.length ? (
                    <Fragment>
                        {_.orderBy(logs, ['date'], ['desc']).slice(0, maxVisible).map((log, i) => (
                            <BookLogHistoryItem key={`log-${log.id}`} log={log} i={i} allowSeens={currentUser?.school?.allowSeens} allowLikes={currentUser?.school?.allowLikes} />
                        ))}
                        {(logs.length > maxVisible || allShown) && <Button outline onClick={toggleLogs}>{allShown ? t('button.view_less') : t('button.view_all')}</Button>}
                    </Fragment>
                ) : <p>{t('no_reading_log_added_yet')}</p>
            }
        </Fragment>
    )
}

export default BookLogHistory;
