import React, { useState } from 'react';
import { Link } from '@reach/router';
import Tab from '../../../components/Tab';
import Tabs from '../../../components/Tabs';
import Guidance from '../../../components/Guidance';
import ReadingBookItem from './ReadingBookItem';
import styles from '../Dashboard.module.scss';
import cx from 'classnames';
import { PlusCircle } from 'react-feather';
import { BOOK_STATUS } from '../../../services/constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Reviews from './Reviews';

const ReadingBooks = ({ firstName, readingBooks, readingBand = null }) => {

    const [showCurrent, setShowCurrent] = useState(true);
    const { t } = useTranslation();


    const renderGuidance = () => {
        return showCurrent ? t('welcome_to_go_read_choose_book_to_log', { studentName: firstName }) : t('here_are_the_books_you_read')
    }

    return (
        <div className="page-wrapper">
            <div className="page-wrapper__main">
                <div className="content-panel">

                    {readingBand && (
                        <h1 className={styles.readingBand}>{t('reading_band')}: <span className={styles.readingBandMeta}><span className={styles.readingBandColor} style={{ backgroundColor: readingBand.colourCode }}></span>{readingBand.name}</span></h1>
                    )}
                    <Tabs>
                        <Tab active={showCurrent} onClick={() => setShowCurrent(true)} >{t('current_books')}</Tab>
                        <Tab active={!showCurrent} onClick={() => setShowCurrent(false)}>{t('previous_books')}</Tab>
                    </Tabs>

                    <div className={styles.bookList}>
                        {_.orderBy(readingBooks.filter(c => c.bookType === 1), 'book.title', 'asc').map(book => {
                            if ((book.status !== BOOK_STATUS.completed && !showCurrent) || (book.status === BOOK_STATUS.completed && showCurrent)) { return null }
                            return <ReadingBookItem key={`book-${book.id}`} book={book.book} readingBook={book} />
                        })}
                        {_.orderBy(readingBooks.filter(c => c.bookType !== 1), 'book.title', 'asc').map(book => {
                            if ((book.status !== BOOK_STATUS.completed && !showCurrent) || (book.status === BOOK_STATUS.completed && showCurrent)) { return null }
                            return <ReadingBookItem key={`book-${book.id}`} book={book.book} readingBook={book} />
                        })}

                        {
                            showCurrent ? (
                                <Link className={cx(styles.bookListItem)} to='/book/new'>
                                    <div className={cx('card', 'card-hover', styles.bookListItemAdd)}>
                                        <div className='card-body'>
                                            <PlusCircle size="60" />
                                            <h3 className='heavy'>{t('new_book')}</h3>
                                        </div>
                                    </div>
                                </Link>
                            ) : !readingBooks.filter(b => b.status === BOOK_STATUS.completed).length && <p>{t('no_books_completed_yet')}</p>
                        }
                    </div>
                </div>
            </div>
            <div className="page-wrapper__side">
                <Guidance text={renderGuidance()} />
                <Reviews />
            </div>
        </div>
    )
}

export default ReadingBooks;
