import React from 'react';
import RatingOption from '../RatingOption';
import styles from './RatingOptions.module.scss';

import { useTranslation } from 'react-i18next';

const RATING_LABELS = [
    '',
    'not_bad',
    'ok',
    'enjoyable',
    'great',
    'incredible'
]


const RatingOptions = ({ rating, setRating, tempRating, setTempRating, displayOnly }) => {

    const { t } = useTranslation();

    return(
        <div className='u-mb-3'>
            <ul className={styles.ratingOpts}>
                {
                    RATING_LABELS.map((_, i) => {
                        if (i === 0) { return null}
                        return(
                            <li key={i}>
                                <RatingOption
                                    displayOnly={displayOnly}
                                    rating={tempRating || rating}
                                    setRating={setRating}
                                    setTempRating={setTempRating}
                                    value={i}
                                />
                            </li>
                        )
                    })
                }
            </ul>
            <p className='heavy'>{ rating === 0 && !tempRating ? t('select_your_rating') : tempRating ? t(RATING_LABELS[tempRating]) : t(RATING_LABELS[rating])}</p>
        </div>
    )
}

export default RatingOptions;
