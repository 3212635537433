import React, { useContext, useState } from 'react';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';
import { FormErrors, Modal, Button, Dropdown } from '@axeedge/go-pupil-components';
import { useTranslation } from 'react-i18next';

import { getBookTypeLabel } from '../../../../services/constants';
import { AuthContext } from '../../../../services/user/AuthProvider';
import { DELETE_READING_BOOK } from '../../services/graphql';
import { MAIN_DASHBOARD_QUERY } from '../../../Dashboard/services/graphql';
import styles from './BookHeader.module.scss';

const BookHeader = ({ studentBook }) => {

    const { t } = useTranslation();
    const { currentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState();

    const canDeleteBook = studentBook.addedBy && studentBook.addedBy.id === currentUser.id && studentBook.logs.filter(log => log.author && log.author.role === "Teacher").length === 0;

    const [deleteReadingBookFromStudent, { loading: deleting }] = useMutation(DELETE_READING_BOOK, {
        onCompleted: ({ deleteReadingBookFromStudent }) => {
            if (deleteReadingBookFromStudent.errors && deleteReadingBookFromStudent.errors.length !== 0) {
                setFormErrors(deleteReadingBookFromStudent.errors);
                return;
            }
            else if(deleteReadingBookFromStudent.deleted) {
                navigate('/');
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: MAIN_DASHBOARD_QUERY }]
    });

    return(
        <div className={styles.bookHeader}>
            <div>
                <p><span className='label label-primary'>{t(getBookTypeLabel(studentBook.bookType))}</span></p>
                <h2>{studentBook.book.title}</h2>
                <p className='u-mb-0'>{studentBook.book.author}</p>
            </div>

            {canDeleteBook && (
                <Dropdown classNames='u-m-left-1'>
                    <li><button onClick={() => setConfirmDelete(true)}>{t('button.delete')}</button></li>
                </Dropdown>
            )} 

            {formErrors.length !== 0 && <FormErrors errors={formErrors} />}

            {confirmDelete && (
                <Modal closeModal={() => setConfirmDelete(false)}>
                    <div className="u-text-center">
                        <h1 className='u-mb-3'>{t('are_you_sure')}</h1 >
                        <div>
                            <Button primary onClick={() => deleteReadingBookFromStudent({
                                variables: {
                                    readingBookId: studentBook.book.id,
                                    studentId: currentUser.id
                                }
                            })}>{deleting ? t('deleting') : t('button.yes')}</Button>
                            <Button outline className='u-ml-2' onClick={() => setConfirmDelete(false)}>{t('button.no')}</Button>
                        </div> 
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default BookHeader;
