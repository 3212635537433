import React, { useState, Fragment, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from '@reach/router';
import * as Yup from 'yup';
import { Button, FormErrors } from '@axeedge/go-pupil-components';
import { useMutation } from '@apollo/react-hooks';
import cx from 'classnames';
import styles from './Reading.module.scss';
import TextareaAutosize from 'react-textarea-autosize';
import { ADD_GROUP_READING_BOOK_LOG } from '../../../../services/graphql';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../../services/user/AuthProvider';

const Reading = ({ groupBook }) => {

    const { currentUser } = useContext(AuthContext);


    const { t } = useTranslation();
    const [formErrors, setFormErrors] = useState([]);
    const [logSaved, setLogSaved] = useState(false);

    const pageStart = !!groupBook.logs.length ? groupBook.logs.slice(-1)[0].pageEnd + 1 : 1

    const [addGroupReadingBookLog, { loading }] = useMutation(ADD_GROUP_READING_BOOK_LOG, {
        onCompleted: ({ addGroupReadingBookLog }) => {
            if (addGroupReadingBookLog.errors && addGroupReadingBookLog.errors.length !== 0) {
                setFormErrors(addGroupReadingBookLog.errors);
                return;
            }
            else if(addGroupReadingBookLog.groupReadingBook.id && !!addGroupReadingBookLog.groupReadingBook.logs.length) {
                setLogSaved(true);
            }
        }
    });

    const schema = Yup.object().shape({
        showEntryField: Yup.boolean(),
        pageStart: Yup.number().typeError(t('please_enter_number'))
            .min(1, t('page_start_min_validation'))
            .max(100000, t('page_start_max_validation')),
        pageEnd: Yup.number().typeError(t('please_enter_number'))
            .max(100000, t('page_end_max_validation')),   
        entry: Yup.string().when('showEntryField', {
            is: true,
            then: Yup.string()
          })
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const onSubmit = values => {
        const currentDate = new Date();
        const { pageStart, pageEnd, entry } = values;
        addGroupReadingBookLog({
            variables: {
                groupReadingBookId: groupBook.id,
                readingDate: currentDate.toString(),
                pageStart,
                pageEnd,
                entry,
                finished: false, // Group books can't be marked as finished by Pupils
                studentIds: [currentUser.id]
            }
        })
    }

    return(
        <Fragment>  
            {!logSaved ? (
                <form onSubmit={handleSubmit(onSubmit)} className={styles.logForm}>
                    <h3 className='heavy'>{t('log_your_reading')}</h3>
                    <p>{t('what_page_have_you_read_up_to')}</p>
                    <div className={styles.logFormPages}>
                        <input
                            type="hidden"
                            name="showEntryField"
                            ref={register}
                            defaultValue={!groupBook.hideComments}
                        />
                        <input
                            name="pageStart"
                            className={cx('basic-form__text-box', styles.logFormPagesField)}
                            ref={register}
                            type="number"
                            defaultValue={pageStart}
                        />to
                        <input
                            name="pageEnd"
                            className={cx('basic-form__text-box', styles.logFormPagesField)}
                            ref={register}
                            type="number"
                        />
                    </div>
                    {errors.pageStart && <p className="basic-form__hint">{errors.pageStart.message}</p>}
                    {errors.pageEnd && <p className="basic-form__hint">{errors.pageEnd.message}</p>}
                    {!groupBook.hideComments && (
                        <Fragment>
                            <p>{t('what_do_you_think_of_what_you_read')}</p>
                            <div className="basic-form__group">
                                <TextareaAutosize
                                    className="basic-form__text-box"
                                    placeholder={t('give_us_your_thoughts')}
                                    name="entry"
                                    minRows={4}
                                    inputRef={register}
                                />
                            </div>
                            {errors.entry && <p className="basic-form__hint">{errors.entry.message}</p>}
                        </Fragment>
                    )}

                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                    <Button type="submit" primary>{loading ? t('saving') : t('add_reading_log')}</Button>
                </form>
            ) : (
                <div className='u-mb-3'>
                    <h1 className='heavy'>{t('awesome')}</h1>
                    <p>{t('reading_log_saved')}</p>
                    <Link to='/'><Button primary>{t('go_back_to_dashboard')}</Button></Link>
                </div>
            )}

        </Fragment>
    )
}

export default Reading;
