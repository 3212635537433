import React, { useState, useContext } from 'react';
import { Button, FormErrors } from '@axeedge/go-pupil-components';
import MyReview from './Components/MyReview';
import ReviewForm from './Components/ReviewForm';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../../services/user/AuthProvider';
import { useMutation } from '@apollo/react-hooks';
import { ASSIGN_BOOK_TO_STUDENT } from '../../../../services/graphql';
import { navigate } from '@reach/router';
import { PlusCircle } from 'react-feather';
import { MAIN_DASHBOARD_QUERY } from '../../../../../Dashboard/services/graphql';

const Completed = ({ studentBook }) => {
    const { t } = useTranslation();
    const { currentUser } = useContext(AuthContext);

    const [noReview, setNoReview] = useState(studentBook.noReview);

    const [formErrors, setFormErrors] = useState([]);


    const [assignReadingBookToStudent, { loading }] = useMutation(ASSIGN_BOOK_TO_STUDENT, {
        onCompleted: ({ assignReadingBookToStudent }) => {
            if (assignReadingBookToStudent.errors && assignReadingBookToStudent.errors.length !== 0) {
                setFormErrors(assignReadingBookToStudent.errors);
                return;
            }
            else if (assignReadingBookToStudent?.studentReadingBook?.id) {
                navigate(`/book/${assignReadingBookToStudent.studentReadingBook.id}`, { state: { book_readded: true }});

            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: MAIN_DASHBOARD_QUERY }]
    });

    const getContent = () => {
        if (studentBook.book.myReview) {
            return <MyReview review={studentBook.book.myReview} allowSeens={currentUser?.school.allowSeens} allowLikes={currentUser?.school?.allowLikes} />
        }
        if (noReview) {
            return <Button outline type="button" onClick={() => setNoReview(false)}>Add review</Button>
        }
        return <ReviewForm studentBookId={studentBook.id} bookId={studentBook.book.id} />
    }

    return (
        <div className='u-mb-3'>
            <div className="u-d-flex justify-between items-center">
                <h1>{t('awesome_you_completed_the_book')}</h1>
                {currentUser &&
                    <button
                        className='btn-reset u-text-primary heavy u-d-flex items-center'
                        disabled={loading}
                        onClick={() => assignReadingBookToStudent({
                            variables: {
                                studentId: currentUser.id,
                                bookId: studentBook.book.id,
                                bookType: studentBook.bookType,
                                hideComments: studentBook.hideComments,
                                isReread: true,
                            }
                        })}
                    >
                        <PlusCircle size={18} className='u-mr-1' />Read again
                    </button>
                }
            </div>
            {formErrors && <FormErrors errors={formErrors} />}
            {getContent()}
        </div>
    )
}

export default Completed;
