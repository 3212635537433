// Queries and mutations unique to the Dashboard module
import { gql } from 'apollo-boost';

// Dashboard data
export const MAIN_DASHBOARD_QUERY = gql`
query currentStudent {
    currentStudent {
        id
        name
        avatarJson
        active
        firstName
        loginCode
        expiredToken
        boomerAvatarUrl
        updatedAt
        currentReadingRank {
            id
            readingBandRank {
                id
                name
                colourCode
            }
        }
        school {
            id
            name
            allowSeens
            allowLikes
            schoolCode
        }
        classes {
            id
            name
            teachers {
                id
                displayName
            }
        }
        readingBooks {
            addedBy {
                id
                displayName
            }
            id
            status
            bookType
            logs {
                id
                date
            }
            book {
                id
                author
                description
                title
                coverUrl
                isbn
                isbn13
            }
        }
        groupReadingBooks {
            id
            status
            addedBy {
                id
                displayName
            }
            book {
                id
                author
                description
                title
                coverUrl
                isbn
                isbn13
            }
            logs {
                id
                date
            }
            students {
                id
            }
        }
    
    }
}
`;


export const GET_SCHOOL_REVIEWS = gql`
query schoolByCode($schoolCode: ID!, $limit: Int, $offset: Int) {
    schoolByCode(schoolCode: $schoolCode)  {
        id
        bookReviews(limit: $limit, offset: $offset) {
            id
            reviewRate
            reviewText
            date
            status
            book {
                id
                title
                author 
            }
            author {
                id
                name
                boomerAvatarUrl
                updatedAt
            }
        }
      
    }
}
`;