import React from 'react';
import styles from './BookItem.module.scss';
import { Button } from '@axeedge/go-pupil-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const BookItem = ({ book, setModalBookType, showSelectBtn }) => {
    const { t } = useTranslation();

    return(
        <div className={cx({'card': showSelectBtn}, 'u-mb-2')}>
            <div className='card-body'>
                <div className={styles.bookItem}>
                    <div className={styles.bookItemBody}>
                        <h2>{book.title}</h2>
                        <p>{book.author}</p>
                        { showSelectBtn && <Button onClick={() => setModalBookType({show: true, book: book})} primary>{t('select_book')}</Button> }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookItem;
