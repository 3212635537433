import React from 'react';
import styles from './RatingOption.module.scss';
import cx from 'classnames';

import starEmpty from '../../../../../../../../images/star-empty.svg';
import starFilled from '../../../../../../../../images/star-filled.svg';

const RatingOption = ({ value, setTempRating, rating, setRating, displayOnly }) => {

    return(
        <button
            type="button"
            onClick={() => !displayOnly && setRating(value)}
            onMouseEnter={() => !displayOnly && setTempRating(value)}
            onMouseLeave={() => !displayOnly && setTempRating(null)}
            className={cx({[styles.ratingBtnDisplay]: displayOnly}, styles.ratingBtn)}
        >
            <img alt='' src={value > rating ? starEmpty : starFilled} />
        </button>
    )
}

export default RatingOption;
