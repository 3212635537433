import React, { useState, Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../../../../services/user/AuthProvider';

import PublisherSearchResults from './components/BookSearchResult/publisherResults';
import Guidance from '../../../../components/Guidance';
import IsbnSearch from './components/IsbnSearch';
//import TitleSearch from './components/TitleSearch';
import CreateBook from './components/CreateBook';

import styles from './AddBook.module.scss';
import * as Icon from 'react-feather';
import { Button } from '@axeedge/go-pupil-components';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useLazyQuery } from '@apollo/react-hooks';
import { SEARCH_BOOK_TITLE } from '../../services/graphql';
import { useTranslation } from 'react-i18next';

const AddBook = () => {
    
    const { currentUser: user } = useContext(AuthContext);
    const { t } = useTranslation();

    const [startIndex, setStartIndex] = useState(0);
    const [showTitleResults, setShowTitleResults] = useState();
    const [searchTerm, setSearchTerm] = useState('');


    const [searchOption, setSearchOption] = useState({
        type: 'TITLE'
    });


    const [booksByTitle, { loading, data, error, fetchMore}] = useLazyQuery(SEARCH_BOOK_TITLE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            searchApi: false
        }
    });

    useEffect(() => {
        if (data && data.booksByTitle && startIndex === 0) {
            setStartIndex(data.booksByTitle.items.length);
        }
    }, [data, setStartIndex, startIndex]);

    useEffect(() => {
        if (data && data.booksByTitle) {
            setShowTitleResults(true);
        }
    }, [data, setShowTitleResults]);

    const { register, handleSubmit, errors } = useForm({
        validationSchema: Yup.object().shape({
            search_term: Yup.string().required(t('please_enter_title'))
        })
    });

    const onSubmit = ({ search_term }) => {
        booksByTitle({variables: {title: search_term}});
        setSearchTerm(search_term);
    }

    const renderGuidance = () => {
        if (searchOption.type === 'ADD_NEW') {
            return t('enter_title_author_well_assign_book_to_you')
        }
        return (
            <Fragment>
                <p>{t('search_book_by_title_barcode')}</p>
                <p>{t('cant_find_book')}</p>
                <Button outline onClick={() => setSearchOption({type: 'ADD_NEW'})}>{t('add_new_book')}</Button>
            </Fragment>
        )
    }

 
    const onFetchMore = () => {
        fetchMore({
            variables: {
              startIndex: startIndex
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              setStartIndex(prev.booksByTitle.items.length + fetchMoreResult.booksByTitle.items.length);
              return {
                ...fetchMoreResult,
                booksByTitle: {...fetchMoreResult.booksByTitle, items: [...prev.booksByTitle.items, ...fetchMoreResult.booksByTitle.items]}
              };
            }
        })
    }
  
    return(
        <div className="page-wrapper">
            <div className="page-wrapper__main">
                <div className="content-panel">
                    {searchOption.type === 'TITLE' && (
                        <Fragment>
                            <div className={styles.searchOptions}>
                                <div className={styles.searchOptionsTitle}>
                                    <h1>{t('find_or_scan_your_book')}</h1>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="basic-form__group">
                                            <input
                                                name="search_term"
                                                placeholder="Search by Title"
                                                className="basic-form__text-box u-mr-1"
                                                ref={register}
                                                type="text"
                                            />
                                            <Button type="submit" primary>{loading ? t('searching') : t('search')}</Button>
                                        </div>
                                        {errors.search_term && <p className="basic-form__hint">{errors.search_term.message}</p>}
                                    </form>  
                                    {error && <p>{error.message}</p>}
                                </div>
                                <button className={styles.searchOptionsScan} onClick={() => setSearchOption({type: 'ISBN'})}>
                                    <Icon.Camera size="40" color="#c33764"/>
                                    <h3 className={styles.optionTitle}>{t('scan_book')}</h3>
                                </button>
                            </div>
                            {data && data.booksByTitle && showTitleResults && (
                                <PublisherSearchResults
                                    setSearchOption={() => setSearchOption({type: 'ADD_NEW'})}
                                    loadingMore={loading}
                                    onFetchMore={onFetchMore}
                                    books={data && data.booksByTitle.items}
                                    total={data && data.booksByTitle.total}
                                    studentId={user.id} />
                            )}
                        </Fragment>
                    )}

                    {searchOption.type === 'ISBN' && <IsbnSearch close={() => {setSearchOption({type: 'TITLE'}); setShowTitleResults(false)}} setSearchOption={() => setSearchOption({type: 'ADD_NEW'})} />}
                    {searchOption.type === 'ADD_NEW' && (
                        <CreateBook
                            studentId={user.id}
                            searchTerm={searchTerm}
                            close={() => {
                                setSearchOption({type: 'TITLE'}); 
                                setShowTitleResults(false);
                                setSearchTerm('');
                            }}
                        />
                    )}
                </div>
            </div>
            <div className="page-wrapper__side">
                <Guidance text={renderGuidance()} />
            </div>
        </div>
    )
}

export default AddBook
