import React, { useContext } from 'react';
import { Link } from '@reach/router';

import LogoutButton from '../../LogoutButton';
import { getUrl, APPS } from '@axeedge/go-shared-utils';
import ls from 'local-storage';
import { useApolloClient } from '@apollo/react-hooks';

import { AuthContext } from '../../../services/user/AuthProvider';
import cx from 'classnames';


const NavLinks = ({ onLogout, styles }) => {

    const client = useApolloClient();
    const { setCurrentUser } = useContext(AuthContext);

    const goToPortal = (path) => {
        ls.clear();
        setCurrentUser(null);
        client.writeData({
            data: {
                loggedIn: false
            }
        });
        window.location.href = `${getUrl(APPS.go_portal_pupil)}`;
    }


    return (
        <>
            <ul className={styles.menuNav}>
                <li><Link to='/' className={cx(styles.menuNavItem, styles.menuNavItemRead)}>Read</Link></li>
            </ul>

            <div className={styles.menuNavExit}>
                <button onClick={() => goToPortal()} className={cx(styles.menuNavItem, styles.menuNavItemHome)}>Home</button>
                <LogoutButton onLogout={onLogout} classes={cx(styles.menuNavItem, styles.menuNavExitBtn)}>
                    Logout
                </LogoutButton>
            </div>
        </>

    )
}

export default NavLinks;

