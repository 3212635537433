import React, { Fragment } from 'react';
import styles from './BookLogHistoryItem.module.scss';
import { Star, FileText, Eye, Heart } from 'react-feather';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import cx from 'classnames';

const BookLogHistoryItem = ({ log, allowSeens, allowLikes }) => {

    const { t } = useTranslation();

    return (
        <div className={styles.logItem}>
            <div className={styles.logItemHeader}>
                {log.isCompletion && (
                    <Fragment><Star className={styles.logItemHeaderIcon} /> <span className="u-mr-2">{t('finished')}:</span></Fragment>
                )}
                <FileText className={styles.logItemHeaderIcon} /> {log.pageStart} &ndash; {log.isCompletion ? 'The End' : log.pageEnd} <span className="u-text-muted u-small u-ml-1">{moment(log.date).format(("DD/MM/YYYY"))}</span>
            </div>

            <div className={styles.logItemComments}>
                <Fragment>
                    {log.entry && <p className='u-mb-0 u-small'>{log.author.role === 'Student' ? t('your_comments') : t('author_comments', { authorName: log.author.name })}:</p>}
                    <div className='u-mb-1'>
                        {log.entry && <span className='u-mr-1'>{log.entry}</span>}
                        {log.author.role === 'Student' && (
                            <>
                                {allowLikes &&
                                    <span className='u-mr-1'>
                                        <Tooltip
                                            disabled={!log.likes || log.likes.length === 0}
                                            size='big'
                                            animateFill={false}
                                            html={
                                                <div className='u-text-left'>
                                                    {log.likes && log.likes.length > 0 && (
                                                        <>
                                                            <p className='u-mb-0'>Liked by:</p>
                                                            {log.likes.map(el => <p key={`like-${el.id}`} className='u-mb-0'>{el.author.displayName}</p>)}
                                                        </>
                                                    )}
                                                </div>
                                            }
                                        >
                                            <span
                                                className={(cx(styles.likeIcon, { [styles.likeIconActive]: log.likes.length > 0 }))}
                                            ><Heart /></span>{log.likes && log.likes.length > 0 && <>({log.likes.length})</>}
                                        </Tooltip>
                                    </span>
                                }
                                {allowSeens &&
                                    <span className='u-mr-1'>
                                        <Tooltip
                                            disabled={!log.views || log.views.length === 0}
                                            size='big'
                                            animateFill={false}
                                            html={
                                                <div className='u-text-left'>
                                                    {log.views && log.views.length > 0 && (
                                                        <>
                                                            <p className='u-mb-0'>Seen by:</p>
                                                            {log.views.map(el => <p key={`seen-${el.id}`} className='u-mb-0'>{el.author.displayName}</p>)}
                                                        </>
                                                    )}
                                                </div>
                                            }
                                        >
                                            <span
                                                className={(cx(styles.seenIcon, { [styles.seenIconActive]: log.views.length > 0 }))}
                                            ><Eye /></span>{log.views && log.views.length > 0 && <>({log.views.length})</>}
                                        </Tooltip>
                                    </span>
                                }
                            </>
                        )}
                    </div>
                </Fragment>
                {log.problemWords && !!log.problemWords.length && (
                    <Fragment>
                        <p>{t('problem_words')}:</p>
                        {log.problemWords.map((pw, i) => <span key={`pw${i}`} className="label label-muted u-mr-1">{pw.word}</span>)}
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default BookLogHistoryItem;
